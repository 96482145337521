<template>
  <div class="form-textarea">
    <label :for="id">{{ label }}</label>
    <textarea :id="id" v-model="inputValue" :disabled="disabled" />
  </div>
</template>

<script>
export default {
  name: 'FormTextarea',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-textarea {
  label {
    color: #a8a8a8;
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  textarea {
    font-family: inherit;
    width: 100%;
    height: 130px;
    border-bottom: 1px solid #e5e5e5;
    resize: none;
    font-size: 16px;
    line-height: 18px;
    color: #3a3a3a;
    padding: 15px 10px;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }
}
</style>
