<template>
  <div class="test-action">
    <template v-if="!isLoading">
      <header class="w-full !mb-4 flex items-center" v-if="isPage">
        <div class="back" @click="back">
          <UIcon name="arrow-left" />
        </div>

        <!-- <input type="text" class="title-test" v-model="test.title" placeholder="Введите название тест" /> -->
        <div class="w-full text-2xl">{{ isCreate ? 'Создание теста' : 'Изменение теста'}}</div>

        <div v-if="!isCreate" class="delete" @click="isDelete = true">
          <UIcon name="delete" />
        </div>

        <UButton @click="actionTest">
          {{ isCreate ? 'Создать тест' : 'Сохранить тест'}}
        </UButton>
      </header>

      <div class="content-test-action">
        <div class="settings !p-6 bg-white rounded-xl mr-4 min-w-[380px]">
          <div class="font-semibold	text-2xl mb-6">Настройки</div>

          <Input
            v-model="test.title"
            id="title"
            type="text"
            placeholder="Название"
            class="mb-16"
          />

          <FormTextarea
            v-model="test.description"
            id="description"
            type="text"
            label="Описание"
            class="mb-14"
          />

          <div class="timer mb-12 w-full">
            <div class="text-sm !mb-3 text-slate-400">Время на выполнение</div>

            <div class="flex w-full">
              <div class="flex bg-slate-100 w-1/3 !mr-3 block-bkg">
                <input type="text" v-model="timer.hour" class="w-full" />
                <div>час</div>
              </div>
              <div class="flex bg-slate-100 w-1/3 !mr-3 block-bkg">
                <input type="text" v-model="timer.min" class="w-full" />
                <div>мин</div>
              </div>
              <!-- <div @click="setNewTime" class="bg-blue-200 flex item-center justify-center w-1/3 flex items-center justify-center rounded-lg cursor-pointer">
                + 15
              </div> -->
            </div>
          </div>

          <div class="flex w-full">
            <div class="attempts w-[100px]">
              <div class="text-sm !mb-3 text-slate-400">Попытки</div>
              <input type="number" v-model="test.attempts" class="block-bkg w-full" />
            </div>

            <div class="!ml-4 w-[130px]">
              <div class="text-sm !mb-3 text-slate-400 whitespace-nowrap">Правильные ответы</div>
              <div class="flex block-bkg w-full">
                <input type="number" v-model="test.rightAnswer" class="w-full" />
                <div class="whitespace-nowrap text-gray-500">из {{ sizeQuestions }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="questions p-6 bg-white rounded-xl w-full">
          <div class="font-semibold	text-2xl mb-6">Вопросы</div>
          <QuestionsWrapper :questions="questions" @save="saveQuestions($event)" />
        </div>
      </div>
    </template>
    <UPreloader v-else />

    <UModal v-model="isDelete" width="400px">
      <div class="text-3xl text-center mb-6 !font-medium">Удалить тест?</div>

      <div class="flex justify-end !mt-4">
        <UButton @click="isDelete = false" variant="gray" class="mr-4">Закрыть</UButton>
        <UButton @click="deleteTest">Удалить</UButton>
      </div>
    </UModal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { coreApi } from '@/api'
import UButton from '@/components/redesign/ui/UButton'
import UPreloader from '@/components/redesign/ui/UPreloader'
import Input from '@/components/redesign/form/FormInput';
import FormTextarea from '@/components/redesign/form/FormTextarea';
import QuestionsWrapper from "@/components/test/questions/QuestionsWrapper"
import UModal from "@/components/redesign/ui/UModal.vue";


export default {
  name: 'TestPage',
  components: {
    UModal,
    UButton,
    UPreloader,
    Input,
    FormTextarea,
    QuestionsWrapper
  },
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDelete: false,
      isLoading: true,
      timer: {
        hour: 0,
        min: 0,
      },
      test: {
        tags: [],
        rightAnswer: 0,
        attempts: 0,
      },
      questions: [],
    };
  },
  computed: {
    ...mapState('main', ['user']),
    isCreate() {
      return !(this.id > 0);
    },
    sizeQuestions() {
      return this.test?.questions?.length || 0;
    },
  },
  methods: {
    async deleteTest() {
      await coreApi.testDelete(this.id);
      this.back();
    },
    back() {
      this.$router.replace(`/tests`);
    },
    async actionTest() {
      this.questions = this.questions.map((item) => {
        if (item.type === 'free') {
          item.answer = item.answers[0]
        }

        return item
      })

      this.test.timer = +this.timer.min + +this.timer.hour * 60;

      const dataParams = {
        user: this.user,
        questions: JSON.stringify(this.questions),
        test: this.test
      }

      console.log('dataParams', dataParams);

      if (this.test.id) {
        await coreApi.testUpdate(dataParams)
      } else {
       await coreApi.testCreate(dataParams)
      }

      // this.getTests()

      if (this.$route.query.course) {
        this.$router.replace(`/course/${this.$route.query.course}`)
      } else {
        this.$router.replace(`/tests/`)
      }
    },
    async init() {
      this.isLoading = true;
      if (this.id  > 0) {
        let test = await coreApi.test({ id: this.id });

        if (test) {
          this.test = {
            ...test,
            questions: test.questions ? JSON.parse(test.questions) : []
          };

          this.timer.hour = Math.floor(test.timer/60);
          this.timer.min = test.timer - (this.timer.hour * 60);

          if (test.questions) {
            this.questions = JSON.parse(test.questions).map((item) => {
              return {
                ...item,
                isVissible: false,
                type: item.type || 'default'
              }
            })
          }
        } else {
          this.back();
        }
      }

      this.isLoading = false;
    },
    saveQuestions(data) {
      this.questions = data
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss">
.test-action {
  width: 100%;

  & > header {
    .back,
    .delete {
      min-width: 48px;
      width: 48px;
      height: 48px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      margin-right: 16px;
      cursor: pointer;

      .icon {
        fill: #A9A9A9;
      }

      &:hover {
        .icon {
          fill: #777777;
        }
      }
    }

    .title-test {
      width: 100%;
      height: 48px;
      padding: 0 16px;
      background: #fff;
      border-radius: 8px;
      margin-right: 16px;
      cursor: pointer;
    }

    .button {
      height: 48px;
      max-width: 220px;
    }
  }

  .content-test-action {
    display: flex;
    align-items: flex-start;

    input {
      background: none;
    }

    .block-bkg {
      background: #F8F8F8;
      border-radius: 6px;
      height: 37px;
      display: flex;
      align-items: center;
      padding: 8px;
    }
  }
}
</style>
