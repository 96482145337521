<template>
  <div class="question">
    <div v-if="isVissible" class="vissible" @click="isVissible = false">
      <div class="index">{{ index + 1 }}</div>
      <div v-if="questionData.title" class="title" v-html="questionData.title"></div>
      <div v-else>Нет вопроса</div>
    </div>

    <div v-else class="question-content">
      <quill-editor
        class="full-main-text"
        v-model="questionData.title"
        @ready="onEditorReady($event)"
        :options="{
          placeholder:'Введите ваш вопрос',
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote'],

              [{ 'list': 'ordered'}, { 'list': 'bullet' }],

              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              ['link', 'image']
            ]
          }
        }"
      />

      <div class="answers">
        <div class="answer" v-for="(answer, index) of question.answers" :key="index" :class="{ active: activeClassForAnswer(answer) }">
          <input type="text" v-model="question.answers[index]" placeholder="Ответ на вопрос">
          <IconCheckOk
            v-if="type !== 'free'"
            class="icon check-ok"
            v-b-tooltip.hover.v-secondary
            title="Выбрать как ответ"
            variant="outline-secondary"
            @click="choseAnswer(answer)"
          />
          <IconClose
            v-if="type !== 'free'"
            class="icon"
            v-b-tooltip.hover.v-secondary
            title="Удалить ответ"
            variant="outline-secondary"
            @click="deleteAnswer(index)"
          />
        </div>
      </div>

      <div class="button-group">
        <div class="select-type">
          <b-form-select
            v-model="type"
            :options="listType"
          />
        </div>
        <div class="addition-info flex items-center">
          <IconRollUp class="icon roll-up" @click="isVissible = true" />
          <IconDelete
            class="icon"
            @click="deleteQuestion"
            v-b-tooltip.hover.v-secondary
            title="Удалить вопрос"
            variant="outline-secondary"
          />
          <IconPlus
            v-if="type !== 'free'"
            class="icon"
            @click="addAnswer"
            v-b-tooltip.hover.v-secondary
            title="Добавить ответ"
            variant="outline-secondary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { coreApi } from '@/api'
import { mapState } from 'vuex'
import IconClose from '@/assets/images/svg/close.svg?inline'
import IconDelete from '@/assets/images/svg/delete.svg?inline'
import IconPlus from '@/assets/images/svg/plus-circle.svg?inline'
import IconCheckOk from '@/assets/images/svg/check-ok.svg?inline'
import IconRollUp from '@/assets/images/svg/roll-up.svg?inline'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { BFormSelect } from 'bootstrap-vue'

export default {
  name: 'Question',
  components: { IconPlus, IconDelete, quillEditor, IconClose, IconCheckOk, IconRollUp, BFormSelect },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      questionData: {},
      isVissible: true,
      listType: [
        {
          value: 'default',
          text: 'Один ответ'
        },
        {
          value: 'multiply',
          text: 'Несколько ответов'
        },
        {
          value: 'free',
          text: 'Ответ текстом'
        },
      ],
      type: 'default', //free, multiply
    }
  },
  watch: {
    question() {
      this.questionData = this.question
      this.isVissible = this.question.isVissible ? false : true
    },
    type(value) {
      this.setFieldType(value)
    }
  },
  computed: {
    ...mapState('main', ['user'])
  },
  methods: {
    activeClassForAnswer(answer) {
      if (this.type === 'multiply') {
        const answerList = this.question.answer.split(' | ')
        return answerList.includes(answer)
      } else {
       return  this.question.answer === answer && answer
      }
    },
    onEditorReady(editor) {
      editor.getModule('toolbar').addHandler('image', () => this.imageHandler(editor));
    },
    imageHandler: async function (editor) {
      const input = document.createElement('input');

      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      input.onchange = async () => {
        const range = editor.getSelection(true);
        const file = input.files[0];
        const result = await coreApi.uploadFile(file, this.user)

        if (result?.file?.url) {
          await editor.insertEmbed(range.index, 'image', result.file.url);
        }
      }
  },
    deleteQuestion() {
      this.$emit("deleteQuestion")
    },
    addAnswer() {
      this.question.answers.push('')
    },
    choseAnswer(answer) {
      if (this.type === 'multiply') {
        let answerList = this.question.answer.split(' | ')
        const indexAnswer = answerList.indexOf(answer)

        if (indexAnswer === -1) {
          answerList.push(answer)
        } else {
          answerList = answerList.filter((index) => index !== answer)
        }

        this.question.answer = answerList.join(' | ')
      } else {
        this.question.answer = answer
      }
    },
    deleteAnswer(index) {
      this.question.answers.splice(index , 1);
    },
    setFieldType(type) {
      this.question.type = type;
      if (type === 'free') {
        this.question.answers = ['']
      } else if (type === 'default') {
        this.question.answer = ''
      }
    }
  },
  mounted() {
    this.questionData = this.question
    this.isVissible = this.question.isVissible ? false : true
    this.type = this.question.type

    setTimeout(() => {
      if (this.question.type === 'free') {
        this.question.answers[0] = this.question.answer
      }
    }, 1000)
  }
}
</script>

<style lang="scss">
.question {
  position: relative;
  width: 100%;
  margin-bottom: 10px;

  img {
    max-width: 150px;
    max-height: 150px;
  }

  .vissible {
    display: flex;
    align-items: center;
    align-items: flex-start;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #E2E2E2;
    border-radius: 10px;

    .index {
      // font-size: 18px;
      font-weight: 600;
      margin-right: 16px;
      // width: 30px;
      // height: 30px;
      // background: #f4f4f4;
      // border-radius: 4px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // position: absolute;
      // top: 10px;
      // right: 10px;
    }
  }

  .question-content {
    .answers {
      margin: 20px 0;

      .answer {
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #a5a5a540;
        padding: 10px;
        border-radius: 8px;
        margin-bottom: 10px;

        input {
          width: 100%;
          background: none;
          border: none;
        }

        .icon {
          cursor: pointer;
          width: 15px;
          height: 15px;
          fill: #b4b4b4;
          margin-left: 15px;

          &:hover {
            fill: #848484;
          }

          &.check-ok {
            width: 20px;
            height: 20px;
          }
        }

        &.active {
          background: #edf9ea;

          input {
            color: #2b5f0b;
            font-weight: 600;
          }

          .icon {
            &.check-ok {
              fill: #3f8d0f;
            }
          }
        }
      }
    }

    .full-main-text {
      width: 100%;
    }

    .button-group {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        cursor: pointer;
        fill: #b4b4b4;
        width: 27px;
        height: 27px;
        margin-right: 20px;

        &.roll-up {
          width: 21px;
          height: 21px;
        }

        &:hover {
          fill: #848484;
        }
      }

      .btn {
        width: max-content;
      }
    }
  }
}
</style>