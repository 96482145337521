<template>
  <div class="test-page">
    <TestAction :id="id" isPage />
  </div>
</template>

<script>
import router from '@/router';
import TestAction from '@/components/redesign/tests/TestAction';

export default {
  name: 'TestPage',
  components: { TestAction },
  computed: {
    id() {
      return router.currentRoute.params.id;
    }
  },
}
</script>

<style lang="scss">
.material-page {
  width: 100%;
}
</style>
