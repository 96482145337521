<template>
  <div class="questions-list">
    <Question v-for="(question, index) of questionsAll" :key="index" :index="index" :question="question" @deleteQuestion="deleteQuestion(index)" />

    <div class="button-group">
      <div class="count-question">
        {{ questionsAll.length }} вопросов
      </div>
      <div class="button" @click="add">
        <feather-icon
          icon="PlusCircleIcon"
          size="19"
          class="add"
        />
        <span>Добавить вопрос</span>
      </div>
    </div>
  </div>
</template>

<script>
import Question from "@/components/test/questions/Question"

export default {
  components: { Question },
  name: 'QuestionsWrapper',
  props: {
    questions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      questionsAll: []
    }
  },
  watch: {
    questions() {
      this.questionsAll = this.questions
    },
    questionsAll() {
      this.$emit("save", this.questionsAll);
    }
  },
  methods: {
    add() {
      this.questionsAll.push({
        isVissible: true,
        type: 'default',
        title: '',
        answers: [],
        answer: ''
      })
    },
    deleteQuestion(index) {
      this.questionsAll.splice(index , 1);
    }
  },
  mounted() {
    this.questionsAll = this.questions
    if (!this.questionsAll.length) {
      this.add()
    }
  }
}
</script>

<style lang="scss" scoped>
.questions-list {
  width: 100%;
  margin-bottom: 20px;

  .button-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 20px;
    padding: 10px;
    background: #ffffff;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgb(140 140 140 / 25%);

    span {
      font-size: 16px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border: 2px solid silver;
      width: max-content;
      cursor: pointer;
      padding: 5px;
      border-radius: 8px;
      
      span {
        display: block;
        font-size: 15px;
      }

      .add {
        cursor: pointer;
        color: silver;
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      .btn {
        width: max-content;
      }

      &:hover {
        background: #f2f2f2;
      }
    }
  }

  .question {
    &:last-child {
      border-bottom: 0;
    }
  }
}
</style>
